exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-bar-tsx": () => import("./../../../src/pages/bar.tsx" /* webpackChunkName: "component---src-pages-bar-tsx" */),
  "component---src-pages-grocery-store-tsx": () => import("./../../../src/pages/grocery-store.tsx" /* webpackChunkName: "component---src-pages-grocery-store-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kitchen-tsx": () => import("./../../../src/pages/kitchen.tsx" /* webpackChunkName: "component---src-pages-kitchen-tsx" */),
  "component---src-pages-poemb-tsx": () => import("./../../../src/pages/poemb.tsx" /* webpackChunkName: "component---src-pages-poemb-tsx" */),
  "component---src-pages-poemk-tsx": () => import("./../../../src/pages/poemk.tsx" /* webpackChunkName: "component---src-pages-poemk-tsx" */),
  "component---src-pages-poemm-tsx": () => import("./../../../src/pages/poemm.tsx" /* webpackChunkName: "component---src-pages-poemm-tsx" */)
}

